<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="分润钱包"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-notice-bar
      color="#1989fa"
      background="#ecf9ff"
      left-icon="volume-o"
      scrollable
    >
      订单结算后实时分润，余额达到100可申请提现！
    </van-notice-bar>
    <div class="bg-white p-3">
      <div
        class="
          d-flex
          justify-content-between
          rounded
          border border-primary
          py-2
          px-3
          wallet
        "
      >
        <div class="balance align-self-center">
          余额: {{ state.balance }}
          <span>昨日收益: {{ state.yesterday_profit }}</span>
        </div>
        <van-button
          type="primary"
          size="small"
          class="align-self-center"
          :disabled="state.balance < 100"
          @click="withdraw"
          >申请提现</van-button
        >
      </div>
    </div>
    <van-dropdown-menu class="van-hairline--bottom" active-color="#1989fa">
      <van-dropdown-item
        v-model="state.type"
        :options="allTypes"
        @change="onTypeChange"
      />
      <van-dropdown-item title="日期" @open="state.showCalendar = true" />
    </van-dropdown-menu>
    <van-list
      v-model:loading="state.loading"
      v-model:error="state.error"
      :finished="state.finished"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        center
        :key="index"
        :title="Moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')"
        :is-link="isLink(item)"
        @click="gotoDetail(item)"
      >
        <template #label>
          <van-tag plain>{{ getWalletLogType(item.type) }}</van-tag>
          {{ getDesc(item) }}
        </template>
        <template #value>
          <span v-if="item.type > 0" class="text-success"
            >+{{ new Number(item.amount * 0.01).toFixed(2) }}元</span
          >
          <span v-else-if="item.type < 0" class="text-danger">
            {{ new Number(item.amount * 0.01).toFixed(2) }}元
          </span>
          <span v-else class="text-secondary">未知</span>
        </template>
      </van-cell>
    </van-list>
  </div>
  <van-calendar
    v-model:show="state.showCalendar"
    type="range"
    position="right"
    color="#1989fa"
    :allow-same-day="true"
    :min-date="new Date(new Date() - 2 * 15552000000)"
    :max-date="new Date()"
    :round="false"
    @confirm="onDateRangeConfirm"
  />
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  Dialog,
  List,
  Tag,
  Toast,
  Calendar,
  NoticeBar,
  DropdownMenu,
  DropdownItem,
} from "vant";
import { getWalletLog, applyWithdraw } from "@/api/account.service";
import { getProfitByDate } from "@/api/agent.service";
import Moment from "moment";
import { useRouter } from "vue-router";
export default {
  components: {
    [Tag.name]: Tag,
    [List.name]: List,
    [Calendar.name]: Calendar,
    [NoticeBar.name]: NoticeBar,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      page: 1,
      items: [],
      loading: false,
      finished: false,
      showCalendar: false,
      balance: "-", // 显示余额, 单位: 元
      balance_raw: 0, // 原始余额, 单位: 分
      yesterday_profit: "-", // 单位: 分

      // 搜索表单字段
      type: 0,
      start_date: "",
      end_date: "",
    });

    // 注意: 大于0表示收入, 小于0表示支持, 其值并不表示真实的 type 值
    const allTypes = [
      { text: "全部", value: 0 },
      { text: "收入", value: 1 },
      { text: "支出", value: -1 },
    ];

    const onTypeChange = () => {
      state.page = 1;
      loadPage();
    };

    const onDateRangeConfirm = (values) => {
      const [start, end] = values;
      state.start_date = Moment(start).format("YYYY-MM-DD");
      state.end_date = Moment(end).format("YYYY-MM-DD");
      state.showCalendar = false; // 提示: 这样会导致日历立即隐藏, 没有动画
      state.page = 1;
      loadPage();
    };

    const loadPage = async () => {
      state.loading = true;
      if (state.page == 1) {
        state.items = [];
      }
      try {
        const { result } = await getWalletLog({
          page: state.page,
          type: state.type,
          start_date: state.start_date,
          end_date: state.end_date,
        });
        if (state.page == 1) {
          const raw = result.items.length > 0 ? result.items[0].balance : 0;
          state.balance = new Number(raw * 0.01).toFixed(2);
          state.balance_raw = raw;
        }
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const getStatusCaption = (item) => {
      if (item.type > 0) {
        return "";
      }
      // 用户主动申请提现时需显示状态
      if (item.type == -1) {
        const allStatus = [
          "提现审核中",
          "", // 支付成功, 此状态不显示状态文本
          "提现支付失败",
          "提现已拒绝",
        ];
        return allStatus[item.status];
      }

      return "";
    };

    const isLink = (item) => {
      if (item.type == 1) {
        return true;
      }
      if (item.type == -1 && item.trade_no) {
        return true;
      }
      return false;
    };

    const getDesc = (item) => {
      if (item.type > 0) {
        return item.order_id ? item.order_id : item.memo;
      }
      if (item.type < 0) {
        if (item.trade_no) {
          return item.trade_no;
        }
        if (item.type == -1) {
          return getStatusCaption(item);
        }
      }
      return item.memo;
    };

    const getWalletLogType = (type) => {
      const types = [
        { text: "分润", value: 1 },
        { text: "电费", value: 2 },
        { text: "上调", value: 3 },
        { text: "提现", value: -1 },
        { text: "下调", value: -2 },
      ];
      for (const item of types) {
        if (type == item.value) {
          return item.text;
        }
      }
      return "未知";
    };

    const gotoDetail = (item) => {
      if (item.type == 1 && item.order_id) {
        router.push({
          name: "AgentChargingOrderDetail",
          query: { order_id: item.order_id },
        });
        return;
      }
      if (item.type == -1 && item.trade_no) {
        router.push({
          name: "AgentWithdrawDetail",
          query: { trade_no: item.trade_no },
        });
        return;
      }
    };

    const withdraw = () => {
      if (state.balance < 100) {
        Toast.fail("余额不足100");
        return;
      }
      Dialog.confirm({
        title: "申请提现",
        message: `您正在申请提现${state.balance}元, 是否继续？`,
      })
        .then(() => {
          processWithdraw();
        })
        .catch(() => {
          // on cancel
        });
    };

    const processWithdraw = async () => {
      if (state.balance_raw < 100) {
        Toast.fail("余额不足100");
        return;
      }
      try {
        await applyWithdraw({
          amount: state.balance_raw,
        });
        Toast.success("申请成功");
        onTypeChange(); // 强制刷新第一页数据
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const loadYesterdayProfit = async () => {
      try {
        const { result } = await getProfitByDate(
          Moment().subtract(1, "days").format("YYYY-MM-DD")
        );
        state.yesterday_profit = new Number(result.profit / 100).toFixed(2);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        console.log("loadYesterdayProfit =>", msg ? msg : error);
      }
    };

    onMounted(() => {
      loadYesterdayProfit();
    });

    return {
      state,
      allTypes,
      onTypeChange,
      onDateRangeConfirm,
      loadPage,
      Moment,
      getStatusCaption,
      isLink,
      getDesc,
      gotoDetail,
      withdraw,
      getWalletLogType,
    };
  },
};
</script>

<style scoped>
.wallet {
  border-style: dashed !important;
}
.balance {
  font-size: 16px;
  font-weight: 500;
}
.balance span {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #28a745;
}
</style>